/* Welcome Section (Portfolio) */
.welcome-section-portfolio {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
    background: linear-gradient(180deg, #1c1f26 0%, #2a2d35 100%);
    color: #d6d6d6;
  }
  
  /* Welcome Container */
  .welcome-container-portfolio {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem;
  }
  
  /* Text Content */
  .welcome-text-portfolio {
    max-width: 600px;
    text-align: left;
  }
  
  .hero-text-portfolio {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    color: #5fd0c5; /* Teal */
    margin-top: 2rem;
    line-height: 1.4;
  }
  
  .hero-p-portfolio {
    font-size: 1rem;
    line-height: 1.6;
    margin-top: 1rem;
    color: #d6d6d6; /* Muted gray */
  }
  
  .explore-button-portfolio {
    background: linear-gradient(180deg, rgba(95, 208, 197, 1) 0%, rgba(137, 227, 218, 1) 100%);
    color: #101820;
    border: none;
    padding: 14px 24px;
    border-radius: 100px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    margin-top: 16px;
    
  }
  
  .explore-button-portfolio:hover {
    background: linear-gradient(180deg, rgba(137, 227, 218, 1) 0%, rgba(95, 208, 197, 1) 100%);
    color: #101820;
    transform: translateY(-2px);
  }

  .explore-buttons-portfolio{
   display: flex;
   flex-direction: row;
   gap: 15px;
  }
  
  /* Image */
  .welcome-image-portfolio img {
    width: 100%;
    max-width: 450px;
    height: auto;
    border-radius: 10px;
  }
  
  /* Skills Section */
  #skills-section {
    padding: 4rem 2rem;
    background: #2a2d35;
    color: #d6d6d6;
  }
  
  /* Projects Section */
  #projects-section {
    padding: 4rem 2rem;
    background: #1c1f26;
    color: #d6d6d6;
  }
  
  /* Responsive Design */
  @media (max-width: 1068px) {
    .welcome-container-portfolio {
      flex-direction: column;
      text-align: center;
      gap: 3rem;
      padding: 2rem;
    }
  
    .hero-text-portfolio {
      font-size: 2rem;
    }
  
    .welcome-image-portfolio img {
      max-width: 350px;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 768px) {
    .welcome-container-portfolio {
      gap: 2rem;
      padding: 0rem;
      margin-top: 2rem;
    }
  
    .hero-text-portfolio {
      font-size: 1.7rem;
      line-height: 1.3;
    }
  
    .hero-p-portfolio {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  
    .explore-button-portfolio {
      font-size: 0.7rem;
      padding: 12px 14px;
      max-width: 200px;
    }
  
    .welcome-image-portfolio img {
      max-width: 300px;
      margin-left: 80px;
    }
  }
  