footer {
    background: #1f2a37; /* Dark background for the footer */
    color: #f0f0f0;
    padding: 40px 5%;
    font-family: 'Montserrat', sans-serif;

}
p{
    text-decoration: none;
}

a{
    text-decoration: none;
}
.footer-main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Adjusts layout for smaller screens */
    margin-bottom: 30px;
    gap: 20px; /* Adds spacing between sections */
}

.footer-section {
    flex: 1;
    min-width: 250px;
    margin-right: 20px;
}

.footer-section h4 {
    color: #ffffff;
    margin-bottom: 15px;
    font-size: 1.2em;
    text-transform: uppercase;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px; /* Adds spacing between list items */
}

.footer-section ul li a {
    color: #a8a8a8;
    text-decoration: none;
    font-size: 0.95rem;
    transition: color 0.3s ease, text-shadow 0.3s ease; /* Smooth transition */
}

.footer-section ul li a:hover {
    color: #5fd0c5; /* Teal hover color for links */
    text-shadow: 0 0 5px #5fd0c5; /* Glow effect on hover */
}

.footer-bottom {
    border-top: 1px solid #444; /* Subtle separator line */
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Adjusts layout for smaller screens */
    gap: 20px; /* Adds spacing between bottom elements */
    align-items: center;
}

.footer-bottom p {
    margin: 0;
    font-size: 0.9rem;
    color: #c5c9cd; /* Muted text color for subtlety */
}

.legal-links {
    display: flex;
    gap: 20px;
    color: #5fd0c5; /* Teal hover color for links */
}

.legal-links a {
    color: #a8a8a8;
    text-decoration: none;
    font-size: 0.95rem;
    transition: color 0.3s ease, text-shadow 0.3s ease; /* Smooth transition */
}

.legal-links a:hover {
    color: #5fd0c5; /* Teal hover color */
    text-shadow: 0 0 5px #5fd0c5; /* Glow effect on hover */
}

/* Social Links Styling */
.social-links {
    display: flex;
    gap: 15px;
    align-items: center;
}

.social-links a {
    color: #a8a8a8;
    font-size: 1.2rem;
    transition: color 0.3s ease, transform 0.3s ease; /* Adds hover effects */
}

.social-links a:hover {
    color: #5fd0c5; /* Teal color on hover */
    transform: scale(1.1); /* Slight zoom effect */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .footer-main {
        flex-direction: column; /* Stacks sections vertically */
        gap: 30px; /* Adds spacing between sections */
    }

    .footer-bottom {
        flex-direction: column; /* Stacks bottom elements */
        align-items: center; /* Centers content */
        text-align: center;
    }

    .legal-links {
        gap: 10px; /* Reduced gap for smaller screens */
    }
}
