 /* Projects Section */
 #projects {
    margin-left: 7rem;
    margin-right: 7rem;
    margin-top: 140px;
    margin-bottom: 80px;
    padding-left: 4rem;
    padding-right: 4rem;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #projects h2 {
    font-size: 2rem; /* Font size for title */
    margin-bottom: .5rem;
    text-align: center; /* Center align the title */
    color: #989898;
  }

  #projects h3{
    margin-bottom: 2rem;
   }
  
  #projects h4{
    text-align: left;
    font-size: 1rem;
  }
  #projects p{
    font-size: 0.8rem;
    margin-top: -1.3rem;
  }
  #projects ul{
    font-size: 0.8rem;
    margin-top: -1.3rem;
    text-align: left;
  }
  .projects {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 2px;
    flex-wrap: wrap; /* Allow projects to wrap */
  }
  
  .project {
    padding: 1rem;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    width: 100%; /* Full width for small screens */
    background: #b6b6b617;
  }
  
  .project img {
    width: 100%;
    max-width: 500px; /* Set a maximum width to maintain scale on large screens */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure images maintain aspect ratio and cover the area */
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Media query for desktop view (two projects per row) */
  @media (min-width: 1024px) {
    .projects {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Two columns on desktop */
      gap: 20px; /* Add space between the columns */
      
    }
  
    .project {
      width: auto; /* Allow width to be determined by the grid layout */
    }
  
    .project img {
      width: 100%;
      max-width: 100%; /* Full width within the grid cell */
      height: auto; /* Keep the aspect ratio */
    }
  }
  
  /* Media query for mobile view (images shrink on smaller screens) */
  @media (max-width: 767px) {
    .project img {
      width: 100%; /* Full width for mobile screens */
      max-width: 100%; /* Shrinks to fit within mobile container */
      height: auto; /* Maintain aspect ratio */
  
    }
    #projects{
      margin-left: 0;
      margin-right: 0;
      padding-left: 1rem;
      padding-right: 1rem;
  
    }
  
  
  }
  
    /* Only show one project at a time on smaller screens */
    @media (max-width: 767px) {
  
      .navigation-buttons {
        display: none;
      }
      .about {
        margin: 0;
      }
      #hero {
        border-radius: 10px;
        background: #3d3d3d17;
        margin: 1rem;
  
      }
      .hero-heading{
          font-size: 2rem;
        }
  
    }
    
    .navigation-buttons {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      gap: 10px;
    }
    
    .navigation-buttons button {
      padding: 10px 20px;
      background: #ff6b6b;
      border: none;
      color: #ffffff;
      border-radius: 10px;
      cursor: pointer;
      transition: background 0.3s ease-in-out;
    }
    
    .navigation-buttons button:hover {
      background: #ff4757;
    }
    
    .project:hover .overlay {
      opacity: 1;
    }
    
    .project .overlay h3 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
    
    .project .overlay p {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    
    .project .overlay .more-button {
      display: inline-block;
      padding: 0.75rem 1.5rem;
      background: #ff6b6b;
      border: none;
      color: #ffffff;
      text-decoration: none;
      border-radius: 10px;
      transition: background 0.3s ease-in-out;
      font-size: 1rem; /* Adjust text size */
    }
    
    .project .overlay .more-button:hover {
      background: #ff4757;
    }
    
    .project-more-details h4 {
      font-size: 1.25rem;
      margin-top: 1rem;
      color: #ff6b6b;
    }
    
    .project-more-details p,
    .project-more-details ul {
      font-size: 1rem;
      margin: 0.5rem 0;
      text-align: left;
    }
    
    .project-more-details ul {
      list-style-type: disc;
      padding-left: 1.5rem;
    }
    
    .project-more-details ul li {
      margin: 0.25rem 0;
    }
    
    .project-more-details img {
      width: 100%;
      height: auto;
      margin-top: 1rem;
      border-radius: 10px;
    }
    
    .project-more-details a {
      color: #ff6b6b;
      text-decoration: none;
    }
    
    .project-more-details a:hover {
      text-decoration: underline;
    }
    
    .project-details {
      padding: 1rem;
      background: #333;
      border-radius: 10px;
      margin-top: 1rem;
    }
    
    .project-details h4 {
      font-size: 1.25rem;
      margin-top: 1rem;
      color: #ff6b6b;
    }
    
    .project-details p,
    .project-details ul {
      font-size: 1rem;
      margin: 0.5rem 0;
      text-align: left;
    }
    
    .project-details ul {
      list-style-type: disc;
      padding-left: 1.5rem;
    }
    
    .project-details ul li {
      margin: 0.25rem 0;
    }
    
    .project-details img {
      max-width: 100%;
      margin-top: 1rem;
      border-radius: 10px;
    }
    
    .project-details a {
      color: #ff6b6b;
      text-decoration: none;
    }
    
    .project-details a:hover {
      text-decoration: underline;
    }
    
    /* Detail overlay styles */
    .project-more-details {
      display: none;
      padding: 2rem;
      background: rgba(51, 51, 51, 0.95); /* Slightly transparent background */
      border-radius: 10px;
      position: fixed; /* Fixed position for overlay */
      top: 0;
      left: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow-y: auto; /* Enable scrolling for overflow content */
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add a shadow for better visibility */
      z-index: 1000; /* Ensure the overlay is on top */
    }
    
    /* Close button styles */
    .project-more-details .close-button {
      display: inline-block;
      padding: 0.75rem 1.5rem;
      background: #ff6b6b;
      border: none;
      color: #ffffff;
      text-decoration: none;
      border-radius: 10px;
      transition: background 0.3s ease-in-out;
      font-size: 1rem;
      cursor: pointer;
      position: fixed; /* Fixed position for the button */
      top: 10px;
      right: 10px;
      z-index: 1001; /* Ensure the button is above the overlay content */
    }
    
    .project-more-details .close-button:hover {
      background: #ff4757;
    }
    