@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');



header.desktop-view {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 10rem;
    text-decoration: none;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 5px;
    z-index: 1000;
    background: #101820;

}
a{
    text-decoration: none;
    color:#f0f0f0; /* Light gray */
}

header.desktop-view nav ul li a:hover{
    color: #5fd0c5; /* Teal hover color for links */
    text-shadow: 0 0 5px #5fd0c5; /* Glow effect on hover */

}

.logo-and-nav-li{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.desktop-nav{
    display: flex;
    flex-direction: row;
    gap: 30px;
}

header.desktop-view nav ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 50px;
}

header.desktop-view nav ul li a {
    

    text-decoration: none;
    color: #f0f0f0; /* Light gray */
    font-weight: 500;
    font-size: 14px;
    transition: color 0.3s ease;
}

.menu-toggle {
    display: none;
    cursor: pointer;
    border-radius: 5px;
}

/* Explore Button in Header */
.header-explore-button {
    color: #5fd0c5; /* Muted teal for text */
    background: none; /* Initially no background */
    border: 1px solid #5fd0c5; /* Teal border */
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 100px;
    transition: background 0.3s ease, color 0.3s ease;
}

.header-explore-button:hover {
    background: linear-gradient(180deg, rgba(95,208,197,1) 0%, rgba(137,227,218,1) 100%);
    color: #101820; /* Dark text for contrast on hover */
}

.cta-social-media{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    gap: 10px;
}

.header-logo img{
    height: 40px;
}

.menu-open-nav{
    display: none;
}
.social-media{
    font-size: 1px;
    
}


@media (max-width: 800px){

    header.desktop-view nav ul{
        gap: 0;
    }
    

}





@media (max-width: 1200px) {

    .menu-open-nav{
        display: block;
        margin-top: -6rem;
        margin-left: -1rem;
    }

    .desktop-nav{
        display: none;
    }

    
   

    .menu-toggle {
        display: block;
        color: #f0f0f0; /* Light gray */
    }

    .desktop-view {
        display: flex;
        justify-content: center; /* Center items */

       
    }

    .menu-social-media a {
        color: #fff;
        font-size: 1.5rem;
        margin: 0 0.5rem;
        transition: color 0.3s ease;
        
      }
      
      .menu-social-media a:hover {
        color: #5fd0c5; /* Teal hover color for links */
        text-shadow: 0 0 5px #5fd0c5; /* Glow effect on hover */
      }


    .header-logo {
        flex-grow: 0; /* Prevent the logo from growing */
        margin: 0; /* Reset any margin */
        width: auto; /* Adjust the logo width as desired */
        height: auto; /* Maintain aspect ratio */
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        gap: 20px;
    }
    .header-logo img{
        height: 30px;
    }

 


   .menu-toggle {
    display: block; /* Ensure the toggle is visible */
}

header.desktop-view nav ul {
    display: none;
    flex-direction: column; /* Arrange items vertically */
    align-content:start;
    align-self: start;
    align-items: start;
    position: fixed;
    top: 45px;
    left: 0;
    width: 90%;
    height: 100vh;
    background: #101820; /* Match header background */
    z-index: 999;
    gap: 0px;
    text-align: left;
    color: #f0f0f0; /* Light gray for links */
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
    
    

}

header.desktop-view nav.menu-open ul {
    display: flex; /* Show menu when toggled */
    
    
}


    .social-media{
        display: flex;
        flex-direction: row;

        display: none;
        
    }

    header.desktop-view{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 4rem;
        
    }

    .header-explore-button{
        font-size: 10px;
    }






header.desktop-view nav ul li {
    margin: 20px 0;
    

    
}

header.desktop-view nav ul li a:hover {
    color: #5fd0c5; /* Teal hover color */
}

.menu-contact {
    flex: 1;
    min-width: 200px;
    margin-right: 20px;
    margin-top: 12rem;
    margin-bottom: 1rem;
}

.menu-contact h4 {
    color: #ffffff;
    margin-bottom: 15px;
    font-size: 1rem;
    text-transform: uppercase;
}

.menu-contact p{
    color: #ffffff;
    font-size: 12px;
}

.menu-contact ul {
    list-style: none;
    padding: 0;
    font-size: 8px;
}

.menu-contact ul li {
    margin-bottom: 10px; /* Adds spacing between list items */
    font-size: 8px;
}

.menu-contact ul li a {
    color: #a8a8a8;
    text-decoration: none;
    font-size: 0.50rem;
    transition: color 0.3s ease, text-shadow 0.3s ease; /* Smooth transition */
}

.menu-contact ul li a:hover {
    color: #5fd0c5; /* Teal hover color for links */
    text-shadow: 0 0 5px #5fd0c5; /* Glow effect on hover */
}






    
   
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  
  .contact-info p {
    font-size: 1rem;
    color: #eaeaea;
    margin: 0.5rem 0;
  }
  
  .contact-info a {
    color: #5fd0c5; /* Teal hover color for links */
    text-shadow: 0 0 5px #5fd0c5; /* Glow effect on hover */
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  .social-media a {
    color: #fff;
    font-size: 1.5rem;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
  }
  
  .social-media a:hover {
    color: #5fd0c5; /* Teal hover color for links */
    text-shadow: 0 0 5px #5fd0c5; /* Glow effect on hover */
  }





/* Mobile Menu Fade-in */
  
  /* Add some spacing for icons and text */
  .menu-open-nav i, .desktop-nav i {
    margin-right: 4px;
  }
  
  /* Optional: Add icon style for Contact Information */
  .menu-contact i {
    margin-right: 8px;
  }
  
