/* Skills Section */
#skills {
    padding-left: 2rem;
    padding-right: 2rem;
    color: #eaeaea;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 80px;
  }
  
  #skills h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #989898;
  }
  
  .skills-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
  }
  
  .skill-card {
    background: #313131;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 10px;
    margin: 0.5rem;
    text-align: left;
    width: 120px; /* Fixed card width */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .skill-card img {
    width: 40px;
    height: 40px;
    margin-bottom: 0.5rem;
  }
  
  .skill-card h3 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .skill-card ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
  }
  
  .skill-card ul li {
    font-size: 0.875rem;
    margin: 0.25rem 0;
  }
  
  /* Media query for mobile view */
  @media (max-width: 767px) {
    .skills-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Two columns on mobile */
      gap: 1rem; /* Space between the cards */
      justify-items: center; /* Center the items */
      padding-left: 1rem;
      padding-right: 1rem;
    }
  
    .skill-card {
      width: 125px; /* Reduce the width of the skill card for mobile */
    
    }
  
    .skill-card img {
      width: 30px; /* Adjust image size to fit the smaller card */
      height: 30px;
    }
  
    .skill-card h3 {
      font-size: 1rem; /* Reduce font size for mobile */
    }
  
    .skill-card ul li {
      font-size: 0.75rem; /* Further reduce the font size for list items */
    }
  }